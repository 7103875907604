.topbar-center-text {
    position: absolute;
    left: 40%;
    transform: translateX(-50%);
    top: 50%;
    transform: translateY(-50%);
    color: gray; /* Adjust color as needed */
  }
  
  .row-top {
    display: flex;
    border: 1px solid #d3d3d3; /* Light grey border */
    border-radius: 5px; /* Rounded corners */
    overflow: hidden; /* Ensure the child border-radius is respected */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* subtle shadow */
    margin: 1px; /* Some margin around the row */
  }
  
  .column {
    display: flex;
    align-items: center; /* Vertically center the content */
    justify-content: center; /* Horizontally center the content */
    font-family: Arial, sans-serif; /* Modern font */
    padding: 8px; /* Padding for a spacious feel */
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .grey-background {
    background-color: #f0f0f0; /* Light grey for a softer look */
    color: #333; /* Darker text for contrast */
    font-weight: bold; /* Make the label bold */
  }
  
  .treatment-center, .htc-faridkot {
    font-size: 10px; /* Font size */
    color: #555; /* Text color */
  }

  /* Hide the row on screens smaller than 600px */
@media screen and (max-width: 600px) {
    .topbar-center-text {
        display: none;
    }
}