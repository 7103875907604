.chart{
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
}

.chartTitle{
    margin-bottom: 20px;
    
}