.container {
    padding-left: 0px;

}

.modal-dialog {
    max-width: 70vw;
    
}

.legend .row:nth-of-type(odd) div {
    background-color:rgb(242, 239, 239);
    }
.legend .row:nth-of-type(even) div {
    background: #FFFFFF;
}

.divTableCell{
    height: auto;
    border-radius: 0px;
    padding-left: 20px;
 
    display: flex;
    align-items: center;
    justify-content: left;
}

.divTableCellRows{
    height: auto;
    border-radius: 0px;
    padding-left: 20px;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-column {
    display: flex;
    flex-wrap:wrap;
}

.flex-column .column-item {
}
