.widget-sm{
    flex: 1;
    padding: 20px;
    margin: 0px 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
}

.widgetSmTitle {
    font-size: 22px;
    font-weight: bold;
}

.widgetSmImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.widgetSmList{
    padding: 0px;
    margin: 0;
    list-style: none;
}
.widgteSmListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.widgetSmUser{
    display: flex;
    flex-direction: column;
}
.widgetSmUsername{
    font-weight: 600;

}
.widgetSmUserTitle{
    font-weight: 300;
}
.widgetSmButton{
    display: flex;
    align-items: center;
    border: none;
    border-radius: 10px;
    padding: 7px 10px;
    background-color: #eeeef7;
    color: #555;
    cursor: pointer;
}
.widgetSmIcon{
    font-size: 16px;
    margin-right: 5px;
}