.notification-hover:hover {
    background-color: #E74C3C; /* Darker red on hover */
  }

  .notification-g-hover:hover {
    background-color: #008000; /* Darker red on hover */
  }
  
  .notification-bg-lg {
    background-color: #EC7063;
    white-space: normal;
    overflow-wrap: break-word;
    display: block;
    max-width: 100%;
    font-size: 1rem;
    color: white;
    cursor: pointer;
  }

  .notification-bg-lg-green {
    background-color: #00b300;
    white-space: normal;
    overflow-wrap: break-word;
    display: block;
    max-width: 100%;
    font-size: 0.9rem;
    color: white;
    cursor: pointer;
  }
  
  .notification-separator {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 3px 0;
  }
  
  .text-justify {
    text-align: justify;
  }
  
  .read-button:hover {
    color: #27AE60 ;
  }