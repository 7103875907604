
.diagnosis-list{
    display: flex;
    flex: 4;
    /* flex-wrap: wrap; */
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: auto;
    /* height:80vh!important; */
    padding: 5px;

    margin: 5px;

}

.diagnosis-child{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.diagnosis-child-icon{
    margin-bottom: 1px;
    margin-top: 5px;
}

.skip-diagnosis{
    width: 100%;
    align-items: center;
    text-align: center;
    margin-top: 10px;
}
