.home{
    flex: 4;
    margin-top: 20px;
}

.homeWidgets{
    display: flex;
}

.parent-container {
    display: flex;
}