.parent-container {
    display: flex;
}

.patient-create{
    flex: 4;
    margin-top: 0px;
    width: 100%;

}

.newPatientForm{
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    padding: 30px;
    margin: 20px;
    border-radius: 5px;
    /* -webkit-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
  
    background: white; */
}
.newPatientFormOnly{
    display: flex;
    flex-wrap: wrap;
}
.newPatientItem{
    width: 250px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
}

.newPatientItem>label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: gray;
}

.newPatientItem>input{
    /* height: 15px !important; */
    font-size: 0.9rem;
    padding: 18px !important;
    line-height: 15px !important;
    outline: none;
    border:1px solid lightgray !important;
    /* border: 1px solid rgb(139, 137, 137); */
    border-radius: 5px;
    /* outline-color: #b91f6c !important; */
}

/* .newPatientGender>input{
    margin-top: 15px;
} */

.newPatientGender>label{
    margin: 10px;
    font-size: 18px;
    color: #555;
}

.newPatientSelect{
    height: 40px;
    border-radius: 5px;
    padding-left: 20px;
    
}

.patientUpdateButton{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
   
    margin-right: 20px;
    margin-bottom: 20px;
}
.submitButton{
    display: flex;
    flex-direction: column;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

.helper-text{
    font-size: smaller;
    font-weight: 300;
}

.helper-icon{
    cursor: pointer;
}

.vaccination{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@import '~antd/dist/antd.min.css';