.delete-icon{
    margin-right: 5px;
    margin-left: 10px;
}

.comments-class{
    padding: 5px;
}
.comment-section{
    width:250px;
}