.widget-lg{
    flex: 2;
    padding: 20px;
    
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    margin-right: 20px;
}

.widgetLgTitle{
    font-size: 22px;
    font-weight: 600;

}

.widgetLgTable{
    width: 100%;
    border-spacing: 20px;
}

.widgetLgTh{
    text-align: left;
}

.widgetLgImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.widgetLgDate, .widgetLgAmount{
    font-weight: 300;
}

.widgetLgButton{
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
}

.widgetLgButton.Approved{
    background-color: #e5faf2;
    color: #3bb077;
}
.widgetLgButton.Declined{
    background-color: #fff0f1;
    color: #d95087;
}
.widgetLgButton.Pending{
    background-color: #ebf1fe;
    color: #2a7ade;
}

.widgetLgUser{
    display: flex;
    align-items: center;
    font-weight: 600;
}