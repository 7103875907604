.parent-container {
    display: flex;
}

.patient-update{
    flex: 4;
    margin-top: 0px;
    width: 100%;
    padding: 20px;
}

.patientTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.patientAddButton{
    width: 80px;
    border:none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}
.patientContainer{
    display: flex;
    margin-top: 20px;
}

.patientShow{
    flex:1;
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09);
}

.patientUpdate{
    flex:2;
    padding: 20px;
    border-radius: 10px;
    margin-left: 20px;
    -webkit-box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09);
}

.patientShowTop{
    display: flex;
    align-items: center;
}

.patientShowImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.patientShowTopTitle{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.patientShowPatientName{
    font-weight: 600;
}

.patientDisease{
    font-weight: 300;
}

.patientShowBottom{
    margin-top: 20px;
}

.patientShowTitle{
    font-size: 14px;
    font-weight: 600;
    color: rgb(175,170,170)
}

.patientShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;
}
.patientShowIcon{
    font-size: 16px !important;
}

.patientShowInfoTitle{
    margin-left: 10px;
}
.patientUpdateTitle{
    font-size: 20px;
    font-weight: 600;
}
.patientUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.patientUpdateItem>label{
    margin-bottom: 5px;
    font-size: 14px;
}
.patientUpdateForm{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.patientUpdateInput{
    border: none;
    width: 250px;
    border-bottom: 1px solid gray;
    height: 30px;
    padding-left:5px ;
}
.patientUpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}
.patientUpdateRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.patientUpdateIcon{
    cursor: pointer;
}

.patientUpdateButton{
    margin-left: 10px;
}

.patientUpdateUpload{
    display: flex;
    align-items: center;

}