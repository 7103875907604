.featured{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.fetauredItem{
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
   

    /* -webkit-box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09); */
    -webkit-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
-moz-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
}

.cost{
    background-color: rgb(255, 255, 255);
}

.revenue{
    background-color: rgb(255, 255, 255);
    /* color: white; */
}

.sales{
    background-color: rgb(255, 255, 255);
    /* color: white; */
}
.featuredTitle{
    font-size: 20px;
}

.featuredMoneyContainer {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featuredMoney{
    font-size: 30px;
    font-weight: bold;
}

.featuredMoneyRate {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon {
    font-size: 14px;
    margin-left: 5px;
    color: green;
}

.featuredIcon.negative {
    color: red;
}

.featuredSub{
    font-size: 15px;
    color: gray;
}