.diagnosis-card{
    margin: 10px;
}

.click-menu{
    cursor: pointer;
}

.click-menu:hover{
    /* font-style: oblique; */
    /* font-weight:bolder; */
    background-color:#4169E1;
    color: white;
}

.card-title{
    font-weight: 350;
}

.selected-menu {
    background-color:#4169E1; /* You can customize this color or add any other styles */
    color: white;
}