.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .loading-text {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #007bff;
  }
  
  .completed-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .checkmark-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #28a745;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkmark {
    width: 50px;
    height: 25px;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
  }
  
  .completed-text {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #28a745;
  }
  