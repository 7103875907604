.patientProfileUpdate{
    flex:2;
    /* flex-basis: 25%; */
    height: fit-content;
    width: 60px;
    /* padding: 20px; */
    border-radius: 10px;
    margin-left: 5px;
    /* margin-top: 10px; */
    margin-right: 10px;
    -webkit-box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px 7px rgba(0,0,0,0.09);
}

.itemHeading{
    font-weight: 600;
    color: darkgray;
}

.tableItems{
    font-size: 14px;
}