.parent-container {
    display: flex;
    height: 60vh;
}

.patient-list{
    flex: 4;
    margin: 20px;
    padding: 20px;
    width: auto;
    height:85vh!important;
    /* border-radius: 20px;
    -webkit-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    background-color: white; */
}

.patientListTitle{
    margin-left: 10px;
}

.patientListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px;
    background-color:#3bb077;
    /* color: white; */
    margin-right: 10px;
    margin-left: 5px;
    cursor: pointer;
}
.patientListDelete{
    color: red;
    cursor: pointer;
}

.editiConContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.editIconItem{
    margin-right: 10px;
    font-size: 15px;
    color:rgb(0, 0, 0);
}

.editIconItem:hover{
    font-weight: bold;
    color:rgb(156, 17, 17);
}

.allPatients {
    margin-left: 10px;
}
.patientListSearch{
    margin-bottom: 10px;
    margin-top: 20px;
}

.patientListSearch>input, #patientSearchInput{
    height: 20px;
    padding: 20px;
    border: 1px solid #555;
    border-radius: 5px;
    margin-left: 10px;
    width: 300px;
}
.patientSearchLabel{
    margin-left: 10px;
    font-weight: 600;
}
