.parent-container {
    display: flex;
    flex-wrap: wrap;
}

.patient-create{
    flex: 4;
    margin-top: 0px;
    width: 100%;

}

.patientProfileUpdate{
    margin-top: 20px;
    margin-right: 20px !important;
    width: 300px !important;
}

.newPatientForm{
    display: flex;
    flex:3 1;
    /* flex-wrap: wrap; */
    
    flex-direction: column;
    padding: 30px;
    border-radius: 20px;
    /* -webkit-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09); */
    /* margin: 20px; */
}


/* .newPatientFormOnly{
    display: flex;
    flex-wrap: wrap;
   
} */
.newPatientItem{
    width: 250px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
}

.newPatientItem>label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: gray;
}

.newPatientItem>input{
    height: 20px;
    padding: 20px;
    border: 1px solid #555;
    border-radius: 5px;
}

.newPatientGender>input{
    margin-top: 15px;
}

.newPatientGender>label{
    margin: 10px;
    font-size: 18px;
    color: #555;
}

.newPatientSelect{
    height: 40px;
    border-radius: 5px;
    padding-left: 20px;
}

.patientUpdateButton{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
   
    margin-right: 20px;
    margin-bottom: 10px;
}
.submitButton{
    display: flex;
    flex-direction: column;
}

.newPatientItem>textarea{
    padding: 10px;
    border-radius: 5px;
    /* width: 520px; */
}

.visit{
    display: flex ;
    flex-wrap: wrap ;
    flex-direction: row ;
    justify-content:baseline;
    margin-left: 30px;
    margin-bottom: 10px;
    width: 100%;
}

@import '~antd/dist/antd.min.css';