.parent-container {
    display: flex;
    height: 60vh;
}

.patient-list{
    flex: 4;
    margin: 20px;
    padding: 20px;
    width: auto;
    height:85vh!important;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
}

.newTCFormUsers{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:20px;
    flex-wrap: wrap;
   
}
